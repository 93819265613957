//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

import axios from 'axios';
import { i18n } from '@/i18n';



export default {
  name: 'app-setLocation-map-page',

  data() {
    return {
    
      model: 
      {
          lng:'',
          lat:'',
          colorValue:'',
          strokeColor:'',
          strokeWeight:'',
          city:'',
          country:'',
          radiusValue:''
          

      },
      center: { lat: 31.2229972, lng: 29.9450273 },
      markers: [
        {
          position: { lat: 31.2229972, lng: 29.9450273 },
        },
      ],

      autocomplete: '',
      address: {},
      currentPlace: null,
      places: [],
 
    };
  },

  computed: {

          // mapGetters to return record , findLoading , saveLoading

    ...mapGetters({

      record: 'place/form/record',
      findLoading: 'place/form/findLoading',
      saveLoading: 'place/form/saveLoading',
    
    }),

    //  check if current user enter to edit or create by id 

    isEditing() {
      return !!this.id;
    },

    // this computed  for enableRtl

    isRTL() {
      return this.$rtl.isRTL;
    },

  },


  mounted() {

    //  call this function to geolocate to current position on the map 
    this.geolocate();

  },




  methods: {

   // mapAction call the  functions from store { doFind , doNew , doUpdate , doCreate , setPlaces }


    // ........................................................................................................................
    ...mapActions({
      doUpdate: 'place/form/doUpdate',
    }),

     // Re initialize all records (reseted records )



    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      const lat = this.center.lat;
      const lng = this.center.lng;

     await  axios.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=AIzaSyDgrVwgswxqsFsvyNVGC7MAPm2xdBtRTHo")


        .then((response) => {

          // console.log(response)
 

      for(var i = 0; i < response.data.results.length;i++){
             for(var j=0;j < response.data.results[i].address_components.length; j++){
                for(var k=0; k < response.data.results[i].address_components[j].types.length; k++){
                        if(response.data.results[i].address_components[j].types[k] == "locality"){
                               let town =
                            response.data.results[i].address_components[j]
                             .long_name;
               
                           this.address.city = town;
                    }
                    else if(response.data.results[i].address_components[j].types[k] == "country"){
                               let country =
                  response.data.results[i].address_components[j]
                    .long_name;
                this.address.country = country;
                }
             }
         }
        }
        });

      this.model.lng = this.center.lng;
      this.model.lat = this.center.lat;
      this.model.city = this.address.city;
      this.model.country = this.address.country;


 


      if (this.isEditing) {
        return this.doUpdate({
          id,
          values,
        });
      } else {
        let record = this.doCreate(values);

        return record;
      }
    },


    // geolocate function call get current postion and set center to that position lat and lng 

    geolocate: function() {
      
      navigator.geolocation.getCurrentPosition(
         
        (position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

        },
      );
    },

    // updateCoordinates take location as parameter and set center lat and lng to that location 

    updateCoordinates(location) {
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };

    },

    i18n(code) {
      return i18n(code);
    },
  },

};



