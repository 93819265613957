//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { CompanyModel } from '@/modules/auth/company-model';
import { FormSchema } from '@/shared/form/form-schema';

const { fields } = CompanyModel;
const formSchema = new FormSchema([
      fields.logo,
      fields.name,
      fields.languages,
      fields.description,
      fields.countryId,
      fields.cityId,
      fields.phoneNumber,
      fields.email,
      fields.websiteLink,
      fields.facebookLink,
      fields.twitterLink,
      fields.instagramLink,
      fields.bankName,
      fields.bankSwiftCode,
      fields.bankIban,
      fields.bankCountry,
      fields.bankCity
]);

export default {
    name: 'app-company-banking-account-page',
    props: ['value'],
    data(){
      return{
        country: '',
        bankName: '',
        city: '',
        swiftCode: '',
        iban: '',

      rules: formSchema.rules(),

      }
    },
    computed:{
      ...mapGetters({
        is_screen_xs: 'layout/is_screen_xs'
      }),
      fields() {
        return fields;
      },
    },
    methods:{
      i18n(key, args) {
        return this.$t(key, args);
      },
      doNext() {
        this.$emit('doNext');
      },
    },
    created(){
      console.log(this.value);
      debugger
    }
}
