import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import JsonField from '@/shared/fields/json-field';
// import EnumeratorField from '@/shared/fields/enumerator-field';
import StringArrayField from '@/shared/fields/string-array-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import ImagesField from '@/shared/fields/images-field';
import DecimalField from '@/shared/fields/decimal-field';
// import i18n from './vueI18n'

function label(name) {
  return i18n(`entities.branch.fields.${name}`);
}
// function enumeratorLabel(name, value) {
//   return i18n(`entities.branch.enumerators.${name}.${value}`);
// }

const fields = {
    id: new IdField('id', label('id')),
    name: new JsonField('name', label('name')),
    description: new JsonField('description', label('description')),
    commercialRegister: new ImagesField(
        'commercialRegister',
        label('commercialRegister'),
        '/saryahCompanyUser/company/commercialRegister', {
          max: 1
        },
    ),
    license: new ImagesField(
        'license',
        label('license'),
        '/saryahCompanyUser/company/license', {
          max: 1
        },
    ),
    logo: new ImagesField(
        'logo',
        label('logo'),
        '/saryahCompanyUser/company/logo', {
          max: 1
        },
    ),

    email: new StringField('email', label('email'), {}),
    websiteLink: new StringField('websiteLink', label('websiteLink'), {}),
    facebookLink: new StringField('facebookLink', label('facebookLink'), {}),
    twitterLink: new StringField('twitterLink', label('twitterLink'), {}),
    instagramLink: new StringField('instagramLink', label('instagramLink'), {}),
    rate: new DecimalField('rate', label('rate'), {}),
    languages: new StringArrayField('languages', label('languages'), {}),
    // legalAuthority: LegalAuthorityEnum
    phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {}),
    countryCode: new StringField('countryCode', label('countryCode'), {}),
    countryId: new StringField('countryId', label('countryId'), {}),
    countryName: new StringField('countryName', label('countryName'), {}),

    cityId: new StringField('cityId', label('cityId'), {}),
    cityName: new StringField('cityName', label('cityName'), {}),
    wallet: new DecimalField('wallet', label('wallet'), {}),
    address: new JsonField('address', label('address')),

    bankName: new StringField('bankName', label('bankName'), {}),
    status: new StringField('status', label('status'), {}),
    bankSwiftCode: new StringField('bankSwiftCode', label('bankSwiftCode'), {}),
    bankIban: new StringField('bankIban', label('bankIban'), {}),
    bankCity: new StringField('bankCity', label('bankCity'), {}),
    bankCountry:  new StringField('bankCountry', label('bankCountry'), {}),
    createdAt: new DateTimeField('createdAt', label('createdAt')),
    updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
    createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class CompanyModel extends GenericModel {
  static get fields() {
    return fields;
  }
}