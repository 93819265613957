//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CompanyProfilePage from '@/modules/auth/components/company-profile/company-profile-page.vue';
import CompanyBankingAccountPage from '@/modules/auth/components/company-profile/company-banking-account-page.vue';
import CompanyOwnerPage from '@/modules/auth/components/company-profile/company-owner-page.vue';
import CompanyVerificationContactPage from '@/modules/auth/components/company-profile/company-verification-contact-page.vue';
import CompanyCommercialRegisterPage from '@/modules/auth/components/company-profile/company-commercial-register-page.vue';
import { routerAsync } from '@/app-module';

import { CompanyModel } from '@/modules/auth/company-model';
const { fields } = CompanyModel;
import { FormSchema } from '@/shared/form/form-schema';
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '@/event-bus.js';
import firebase from 'firebase/app';
import 'firebase/storage';
import i18n from '@/vueI18n'
const formSchema = new FormSchema([
  // fields.avatarsProfile,
  fields.logo,
  fields.name,
  fields.description,
  fields.commercialRegister,
  fields.license,
  fields.email,
  fields.websiteLink,
  fields.facebookLink,
  fields.twitterLink,
  fields.instagramLink,
  fields.languages,
  fields.phoneNumber,
  fields.countryCode,
  fields.countryId,
  fields.cityId,
  fields.countryName,
  fields.cityName,
  fields.wallet,
  fields.address,
  fields.bankName,
  fields.bankSwiftCode,
  fields.bankIban,
  fields.bankCountry,
  fields.bankCity
]);
export default {
  name: 'app-company-form-page',

  components:{
    [CompanyProfilePage.name]: CompanyProfilePage,
    [CompanyBankingAccountPage.name]: CompanyBankingAccountPage,
    [CompanyOwnerPage.name]: CompanyOwnerPage,
    [CompanyVerificationContactPage.name]: CompanyVerificationContactPage,
    [CompanyCommercialRegisterPage.name]: CompanyCommercialRegisterPage

  },
  computed:{
    fields() {
      return fields;
    },
    ...mapGetters({
      company: 'auth/company',
      is_screen_xs: 'layout/is_screen_xs',
      companyId: 'auth/companyId',
      currentUser: 'auth/currentUser'
    }),
    language(){
      return i18n.locale
    }
  },

  data() {
    return {
      step: 1,
      model: null,
      id: null,
      logo: null,
      license: null,
      commercialRegister: null,
      loadingImages: false
    };
  },
  methods: {
    prev() {this.step -= 1},
    next(value) {
      // if(this.step == 1){

      // }
      if(this.step == 2){
        console.log(value);
        this.logo = value        
        if(!value){          
          EventBus.$emit('imageIsRequired')
        }
        else{
          this.$refs.stepper.next()
        }
      }
      // console.log(this.model);
      // debugger
      // this.model = data;
      // console.log('Data : ', data);
      // this.step += 1;
      else{
        this.$refs.stepper.next()
      }
    },
    ...mapActions({
      doCreateCompany: 'auth/doCreateCompany',
      doUpdateCompany: 'auth/doUpdateCompany',
      doUpdateProfile: 'auth/doUpdateProfile',
    }),
    async doSubmitCompanyOwner(){
      console.log(this.currentUser);
      // debugger
      await this.doUpdateProfile(this.currentUser)
    },
    async doSubmitCompany(values){
      // await this.doSubmitCompanyOwner()
      // debugger
      this.license = values.license
      this.commercialRegister = values.commercialRegister      
     if(!this.commercialRegister){
        EventBus.$emit('formImageIsRequired', 'commercialRegister')
      }
      else if(!this.license){
        EventBus.$emit('formImageIsRequired', 'license')
      }
      else{
        console.log(this.model);
        this.id = await this.doCreateCompany(this.model)
        debugger
        console.log(this.id);
        this.loadingImages = true
        await this.uploadLogo(`saryahCompany/company/${this.id}/logo`, this.logo)
      }
      
      
      //  return routerAsync().push(`/${this.language}/auth/empty-permissions`)
      // return routerAsync().push(`/${this.language}`)

    },
   
    

     async uploadLogo(path, request) {
            console.log(request);            
            const ref = firebase.storage().ref();
            // const extension = this.extractExtensionFrom(
            //     request.file.name,
            // );
            // console.log(extension);
            // const fullPath = `${path}/${request.file.lastModified}.${extension}`;
            const fullPath = `${path}/${request.name}`;
            // console.log('full path',fullPath)            
            const task = ref.child(fullPath).put(request);

            await task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                const percent =
                (snapshot.bytesTransferred /
                    snapshot.totalBytes) *
                100;
                console.log('precent', percent);
            },
            (error) => {
                // request.onError(error);
                console.log(error);
            },
            () => {
                task.snapshot.ref
                .getDownloadURL()
                .then(async (url) => {
                    console.log(url);
                    this.model.logo.publicUrl = url
                    this.model.logo.name = request.name
                    console.log(this.model.logo);  
                    this.uploadCommercialRegister(`saryahCompany/company/${this.id}/commercial register`, this.commercialRegister)
                })
                .catch((error) => {
                    console.log('error', error);
                });
            },
            );
        },
        async uploadCommercialRegister(path, request){
            console.log(request.file);            
            const ref = firebase.storage().ref();
            const fullPath = `${path}/${request.file.name}`;
            console.log('full path',fullPath)            
            const task = ref.child(fullPath).put(request.file);

            await task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                const percent =
                (snapshot.bytesTransferred /
                    snapshot.totalBytes) *
                100;
                console.log('precent', percent);
            },
            (error) => {
                // request.onError(error);
                console.log(error);
            },
            () => {
                task.snapshot.ref
                .getDownloadURL()
                .then(async (url) => {
                    console.log(url);
                    this.model.commercialRegister.publicUrl = url
                    this.model.commercialRegister.name = request.file.name
                    console.log(this.model.commercialRegister); 
                    this.uploadLicense(`saryahCompany/company/${this.id}/license`, this.license)
                })
                .catch((error) => {
                    console.log('error', error);
                });
            },
            );
        },


        async uploadLicense(path, request){
            console.log(request.file);            
            const ref = firebase.storage().ref();
            const fullPath = `${path}/${request.file.name}`;
            console.log('full path',fullPath)            
            const task = ref.child(fullPath).put(request.file);

            await task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                const percent =
                (snapshot.bytesTransferred /
                    snapshot.totalBytes) *
                100;
                console.log('precent', percent);
            },
            (error) => {
                // request.onError(error);
                console.log(error);
            },
            () => {
                task.snapshot.ref
                .getDownloadURL()
                .then(async (url) => {
                    console.log(url);
                    this.model.license.publicUrl = url
                    this.model.license.name = request.file.name

                    console.log(this.model);
                    // debugger

                    await this.doUpdateCompany({id: this.id, values: {
                      ...this.model
                    }})
                    
                    console.log(this.companyId);
                    this.currentUser['companyId'] = this.companyId
                    await this.doUpdateProfile(this.currentUser)
                    this.loadingImages = false
                    // debugger
                    return routerAsync().push(`/${this.language}`)
                    
                    // this.next()
                })
                .catch((error) => {
                    console.log('error', error);
                    // request.onError(error);
                });
            },
            );
        }
  },
  created(){
    console.log(this.currentUser);
    if(this.currentUser.workingEmail || this.currentUser.alternativePhoneNumber || this.currentUser.avatars){
      this.step = 2
    }

    // debugger
    this.model = formSchema.initialValues(this.company);
    this.model.name = this.model.name || { en: undefined, ar: undefined }
    this.model.address = this.model.address || { en: undefined, ar: undefined }
    this.model.description = this.model.description || { en: undefined, ar: undefined }
    this.model.logo = {
      publicUrl: undefined,
      name: undefined,
    }
    this.model.commercialRegister = {
      publicUrl: undefined,
      name: undefined,
    }
    this.model.license = {
      publicUrl: undefined,
      name: undefined,
    }
    this.model.status = 'pending'
    // this.model.logo = {
    //   publicUrl: 'https://firebasestorage.googleapis.com/v0/b/saryah-31e36.appspot.com/o/saryahCompany%2Fplan-cities-ic-active.svg?alt=media&token=d0fbd9b9-5902-48d4-a0b7-f2d764e61128',
    //   name: 'image.png'
    // }
    // this.model.commercialRegister = {
    //   publicUrl: 'https://firebasestorage.googleapis.com/v0/b/saryah-31e36.appspot.com/o/saryahCompany%2Fplan-cities-ic-active.svg?alt=media&token=d0fbd9b9-5902-48d4-a0b7-f2d764e61128',
    //   name: 'image.png'
    // }
    // this.model.license = {
    //   publicUrl: 'https://firebasestorage.googleapis.com/v0/b/saryah-31e36.appspot.com/o/saryahCompany%2Fplan-cities-ic-active.svg?alt=media&token=d0fbd9b9-5902-48d4-a0b7-f2d764e61128',
    //   name: 'image.png'
    // }
  }
};
