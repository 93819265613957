import { render, staticRenderFns } from "./company-profile-page.vue?vue&type=template&id=1c58d463&scoped=true&"
import script from "./company-profile-page.vue?vue&type=script&lang=js&"
export * from "./company-profile-page.vue?vue&type=script&lang=js&"
import style0 from "./company-profile-page.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./company-profile-page.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./company-profile-page.vue?vue&type=style&index=2&id=1c58d463&scoped=true&lang=css&"
import style3 from "./company-profile-page.vue?vue&type=style&index=3&id=1c58d463&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c58d463",
  null
  
)

export default component.exports
import {QSeparator,QForm,QInput,QSelect,QRadio,QItem,QItemSection,QItemLabel,QBtn,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSeparator,QForm,QInput,QSelect,QRadio,QItem,QItemSection,QItemLabel,QBtn,QField})
