import { render, staticRenderFns } from "./company-commercial-register-page.vue?vue&type=template&id=c26711e2&scoped=true&"
import script from "./company-commercial-register-page.vue?vue&type=script&lang=js&"
export * from "./company-commercial-register-page.vue?vue&type=script&lang=js&"
import style0 from "./company-commercial-register-page.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./company-commercial-register-page.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./company-commercial-register-page.vue?vue&type=style&index=2&id=c26711e2&scoped=true&lang=css&"
import style3 from "./company-commercial-register-page.vue?vue&type=style&index=3&id=c26711e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c26711e2",
  null
  
)

export default component.exports
import {QSeparator,QForm,QBtn,QField,QRadio} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSeparator,QForm,QBtn,QField,QRadio})
