//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapActions } from 'vuex';
// import { routerAsync } from '@/app-module';
// import { i18n } from '@/i18n';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import  setBranchLocation from '@/modules/auth/components/company-profile/setLocation-map-page.vue'
import { UserModel } from '@/modules/auth/user-model';
const { fields } = UserModel;
import { FormSchema } from '@/shared/form/form-schema';
import firebase from 'firebase/app';
import 'firebase/storage';

const formSchema = new FormSchema([
  fields.avatarsProfile,
  fields.avatars,
  fields.fullName,
  fields.firstName,
  fields.lastName,
  fields.workingEmail,
  fields.alternativePhoneNumber,
  fields.countryCode,
  fields.companyId
]);
export default {
  name: 'app-company-owner-page',

  components:{
    VuePhoneNumberInput,
    [setBranchLocation.name]: setBranchLocation,
  },

  data() {
    return {
      avatar: null,
      alternativePhoneNumber: '',
      model:{
        firtName:'',
        lastName:''
      },
      loadingImages: false,
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      CCode:'',
      isCCode:'',
    };
  },
  computed: {
    fields() {
      return fields;
    },
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      loading: 'auth/loading',
      currentUser: 'auth/currentUser',
      signedIn: 'auth/signedIn',
      loadingUpdateProfile: 'auth/loadingUpdateProfile',
      companyId: 'auth/companyId'
    }),
    isRTL() {
      return localStorage.getItem('language') == 'ar';
    },
  },
  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile',
    }),
    i18n(key, args) {
        return this.$t(key, args);
      },    
    // onUpdate(data) {
    //   this.CCode = data.countryCode;
    // },
    onUpdate(data) {
      // console.log(data);
      // this.value.countryCode = data.countryCode;
      this.model.alternativePhoneNumber = data.formatInternational
    },
    doReset() {
      this.modelBranchSize = { } 
    },
    doPrev() {
      this.$emit('doPrev');
    },    
    doNext() {
      this.$emit('doNext', this.model);
    },
    getProfileImg(value){
      this.avatar = value
    },
    async doSubmit() {
      // this.$emit('doNext', this.modelBranchSize);
      // const values = formSchema.cast(this.model);
      // this.model.fullName = this.currentUser.fullName
      this.model.accountType = this.currentUser.accountType,
      this.model.countryCode = this.currentUser.countryCode
      this.model.phoneNumber = this.currentUser.phoneNumber
      this.model.lang = this.currentUser.lang
      this.model.companyId = this.companyId
      // debugger

      await this.doUpdateProfile(this.model)
      // debugger
      if(this.avatar){
        await this.uploadAvatar(`saryahCompany/user/${this.currentUser.id}/avatar`, this.avatar)
      }
      else{
        // routerAsync().push('/auth/add-verificationContact') ;
        this.doNext()
      }

    },

    async uploadAvatar(path, request){
            this.loadingImages = true
            console.log(request);            
            const ref = firebase.storage().ref();
            const fullPath = `${path}/${request.name}`;
            console.log('full path',fullPath)            
            const task = ref.child(fullPath).put(request);

            await task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                const percent =
                (snapshot.bytesTransferred /
                    snapshot.totalBytes) *
                100;
                console.log('precent', percent);
            },
            (error) => {
                // request.onError(error);
                console.log(error);
            },
            () => {
                task.snapshot.ref
                .getDownloadURL()
                .then(async (url) => {
                    console.log(url);
                    this.model.avatars[0].publicUrl = url
                    this.model.avatars[0].name = request.name
                    this.doUpdateProfile(this.model)
                    this.loadingImages = false
                    this.doNext()
                    // routerAsync().push('/auth/add-verificationContact') ;

                    // this.next()
                })
                .catch((error) => {
                    console.log('error', error);
                    // request.onError(error);
                });
            },
            );
        }
    
    },
    created(){
      // console.log(this.currentUser);
      // debugger
        this.model = formSchema.initialValues(this.currentUser);
        this.model.email = this.currentUser.email
        this.model.avatars = []
        this.model.avatars[0] = {
          publicUrl: this.currentUser.avatars && this.currentUser.avatars.length > 0 ? this.currentUser.avatars[0].publicUrl : '',
          name: this.currentUser.avatars && this.currentUser.avatars.length > 0 ?  this.currentUser.avatars[0].name :  '',
        }
        this.alternativePhoneNumber = this.currentUser.alternativePhoneNumber

        // console.log(this.model);
        // debugger
    }
};
