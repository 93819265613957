//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { UserModel } from '@/modules/auth/user-model';
import { CompanyModel } from '@/modules/auth/company-model';

import { FormSchema } from '@/shared/form/form-schema';
// import { i18n } from '@/i18n';
import i18n from '@/vueI18n'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { routerAsync } from '@/app-module';
const { fields } = CompanyModel;

const formSchema = new FormSchema([
      fields.logo,
      fields.name,
    fields.languages,
    fields.description,
    fields.countryId,
    fields.cityId,
    // fields.countryName,
    // fields.cityName,
    fields.phoneNumber,
    fields.email,
    fields.websiteLink,
    fields.facebookLink,
    fields.twitterLink,
    fields.instagramLink
]);

export default {
  name: 'app-company-profile-page',

  props: ['id','value'],

  components:{
    VuePhoneNumberInput,    
  },
  data() {
    return {
      logo: null,
      model: {
        // name: '',
        // languages: '',
        // description: '',
        // authority: '',
        // country: '',
        // city: '',
        // countryCode: '',
        // phoneNumber: '',
        // websiteLink: '',
        // email: '',
        // facebookLink: '',
        // twitterLink: '',
        // instagramLink: '',
      },
      citiesOptions: [],
      popupShow: false,

      options: [
        'Arabic', 'English', 'German', 'French', 'Spanish'
      ],
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      CCode: 'SA',
      language: this.isRTL ? 'en' : 'ar'
    };
  },

  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      loading: 'auth/loading',
      currentUser: 'auth/currentUser',
      signedIn: 'auth/signedIn',
      citiesRows: 'city/list/rows',  
      loadingCities: 'city/list/loading', 
    }),
    fields() {
      return fields;
    },
    isRTL() {
      // return localStorage.getItem('language') == 'ar';
      return i18n.locale == 'ar'
    },
    isCCode() {
      if (!this.model.countryCode) {
        return this.CCode;
      } else {
        return this.model.countryCode;
      }
    },
    cities() {
      return this.citiesRows.map(item => {
        return {
          value: item.id,
          label: item.name['en'],
        }
      })
    },
  },
  created(){
    // console.log(this.value);
    // this.model = formSchema.initialValues(this.company);
    // this.model.name = this.model.name || { en: 'undefined', ar: 'undefined' }
    // this.model.description = this.model.description || { en: 'undefined', ar: 'undefined' }
    // debugger
  },
  // async created() {
  //   this.model = formSchema.initialValues(this.currentUser);
  // },
  methods: {
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.cities
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.citiesOptions = this.cities.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile',
      doFetchCities: 'city/list/doFetch',
    }),
    // i18n(code) {
      // return i18n(code);
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    onUpdate(data) {
      this.value.countryCode = data.countryCode;
      this.value.phoneNumber = data.formatNational
    },
    doPrev() {
      this.$emit('doPrev');
    },    
    doNext() {
      this.$emit('doNext', this.logo);
    },

    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }

      const values = formSchema.cast(this.model);
    
      let userProfle= {
          firstName:  this.currentUser.firstName , 
          lastName:    this.currentUser.lastName ,
          phoneNumber: this.currentUser.phoneNumber ,
          countryCode: this.currentUser.countryCode ,
          avatars : values.avatars ,
          lang : this.currentUser.lang ,
          accountType : this.currentUser.accountType
      }
      this.doUpdateProfile(userProfle);
      routerAsync().push('/auth/add-companyBranches') ;
    },
    uploadCompanyLogo(value){
      this.logo = value
    }
    // uploadCompanyLogo(fileList){
    //   console.log(fileList);                      
    //   this.logo = fileList[0]  
    //   // debugger
    // },
  },
  async mounted() {
    await this.doFetchCities()
  },
  // created(){
  //   console.log('icons',this.$q.iconSet)
  //   this.$q.iconSet.arrow.dropdown = 'keyboard_arrow_down'
  //   // this.$q.icon.input.dropdown = 'arrow_downward'
  // }
};
