//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// import { i18n } from '@/i18n';
import { CompanyModel } from '@/modules/auth/company-model';
const { fields } = CompanyModel;
import { FormSchema } from '@/shared/form/form-schema';
const formSchema = new FormSchema([
  fields.commercialRegister,
]);
export default {
  name: 'app-company-commercial-register-page',
  props: ['value', 'loadingImages'],

  components: {
    // VuePhoneNumberInput,
  },
  data() {
    return {
      model:{

      },
      modelVerificationContact: {
        country: '',
        file: null,
        city: '',
        websiteLink: '',
        twitterLink: '',
        email: '',
        instagramLink: '',
        facebookLink: '',
        phoneNumber: '',
      },
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      CCode: '',
      isCCode: '',
      license: null,
      commercialRegister: null
    };
  },

  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      loading: 'auth/loading',
      currentUser: 'auth/currentUser',
      signedIn: 'auth/signedIn',
      loadingCompany: 'auth/loadingCompany'
    }),
    isRTL() {
      return localStorage.getItem('language') == 'ar';
    },
    fields() {
      return fields;
    },
  },
  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile',
    }),
    // i18n(code) {
    //   return i18n(code);
    // },    
    i18n(key, args) {
      return this.$t(key, args);
    },
    onUpdate(data) {
      this.CCode = data.countryCode;
    },
    doPrev() {
      this.$emit('doPrev');
    },    
    doNext() {
      // this.$emit('doNext', this.modelVerificationContact);
      this.$emit('doNext', {
        license: this.license,
        commercialRegister: this.commercialRegister
      })
    },
    uploadLicense(fileList){
      console.log(fileList);                      
      this.license = fileList[0] 
    },
    uploadCommercialRegister(fileList){
      console.log(fileList);                      
      this.commercialRegister = fileList[0]  
      // debugger
    },

    async doSubmit() {
      const values = formSchema.cast(this.model);
      console.log(values)
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      // console.log("modelVerificationContact is : " , this.modelVerificationContact)
    },
  },
};
